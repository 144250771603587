import { useContext, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import StoreContext from "../../context/StoreContext";
import useConstructor from "../../config/hooks/useConstructor";
import {
  convertToCSV,
  createNotification,
  downloadCsv,
  RenderIf,
  unflattenList,
} from "../../config/utils";
import AnalysesController from "../../config/apiUtils/AnalysesController";
import moment from "moment";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import Analyses from "../Analyses";
import CsvButton from "../../components/Buttons/CsvButton";
import { getColumnsMapForCsv } from "../../config/gridUtils";
import AgGrid from "../../components/AgGrid";

const columns = [
  {
    headerName: "Organisme",
    field: "nomorganismestockeur",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Surfaces",
    children: [
      {
        headerName: "Surface printemps",
        field: "surfaceprintemps",
        defaultFlex: 1,
        filter: true,
      },
      {
        headerName: "Surface automne",
        field: "surfaceautomne",
        defaultFlex: 1,
        filter: true,
      },
      {
        headerName: "Surface totale",
        field: "surfacetotale",
        defaultFlex: 1,
        filter: true,
      },
    ],
  },
  {
    headerName: "Quantités",
    children: [],
  },
];

export default function SurfaceVarieteOS() {
  const store = useContext(StoreContext);

  const mapKeysNames = {
    nomorganismestockeur: "Organisme stockeur",
    surfaceprintemps: "Surface printemps",
    surfaceautomne: "Surface automne",
    surfacetotale: "Surface totale",
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getVarieteColumnName = (idrefvariete) => {
    return "variete." + idrefvariete;
  };

  const loadGrid = () => {
    const columnVarieteParent = columns.find(
      (c) => c.headerName === "Quantités",
    );
    //Pour chaque organisme stockeur
    data.forEach((organisme) => {
      //Pour chaque variété, on vérifie si la variété est présente dans les colonnes du tableau
      organisme.varietes.forEach((variete) => {
        // On met à plat les données pour l'affichage dans le tableau
        organisme[getVarieteColumnName(variete.idrefvariete)] =
          variete.quantite;

        //Pas présente par défaut
        const varieteInColumns = columnVarieteParent.children.some(
          (column) =>
            column.name === getVarieteColumnName(variete.idrefvariete),
        );

        if (!varieteInColumns) {
          columnVarieteParent.children.push({
            headerName: variete.libelle,
            field: getVarieteColumnName(variete.idrefvariete),
            defaultFlex: 1,
            filter: true,
          });
        }
      });
    });
  };

  useConstructor(async () => {
    setLoading(true);
    setData(
      await AnalysesController.getSurfaceVarieteOS(store.millesime.idmillesime),
    );
    setLoading(false);
  });

  loadGrid();

  return (
    <Analyses>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <CardTitle tag={"h5"}>
                    Surfaces par variétés et par organisme stockeur
                  </CardTitle>
                </Col>
                <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <RenderIf isTrue={data.length > 0}>
                    <CsvButton
                      color="primary"
                      onClick={async () => {
                        // On récupère une version non modifiée des data pour traitement tableau croisé dynamique
                        const temp = [...data];
                        temp.forEach((item) => {
                          item.varietes.forEach((variete) => {
                            item[variete.libelle] = variete.quantite;
                            mapKeysNames[variete.libelle] = variete.libelle;
                          });
                          delete item.varietes;
                        });
                        const name =
                          "analyses_surface_variete_os_" +
                          store.millesime.idmillesime +
                          "_" +
                          moment().format("YYYY-MM-DD");
                        downloadCsv(
                          convertToCSV(temp, ";", getColumnsMapForCsv(columns)),
                          name,
                        );
                        createNotification(
                          "info",
                          "Information",
                          "Export téléchargé dans le fichier : " + name,
                          10000000,
                        );
                      }}
                    />
                  </RenderIf>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <RenderIf isTrue={loading}>
                <SpinLoadingAnimation />
              </RenderIf>
              <RenderIf isTrue={!loading}>
                <AgGrid
                  columnDefs={columns}
                  rowData={unflattenList(data)}
                  showFilterLine
                />
              </RenderIf>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Analyses>
  );
}
