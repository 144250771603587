import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import ActionsGroupees from "../ActionsGroupees";
import { useContext, useState } from "react";
import StoreContext from "../../context/StoreContext";
import ActionGroupeeController from "../../config/apiUtils/ActionGroupeeController";
import { columns } from "../../components/Grids/ActionsGroupees/historiqueformation";
import useConstructor from "../../config/hooks/useConstructor";
import { convertToCSV, downloadCsv, RenderIf } from "../../config/utils";
import CsvButton from "../../components/Buttons/CsvButton";
import moment from "moment/moment";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import AgGrid from "../../components/AgGrid";
import { getColumnsMapForCsv } from "../../config/gridUtils";

export default function HistoriqueFormation() {
  const store = useContext(StoreContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isMillesime, setIsMillesime] = useState(true);

  const loadData = async () => {
    setData([]);
    setLoading(true);
    if (isMillesime) {
      setData(
        await ActionGroupeeController.getHistoriqueFormation(
          store.millesime.idmillesime,
        ),
      );
    } else {
      setData(await ActionGroupeeController.getHistoriqueFormation());
    }
    setLoading(false);
    setIsMillesime(!isMillesime);
  };

  useConstructor(async () => {
    await loadData();
  });

  return (
    <ActionsGroupees>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <CardTitle tag={"h5"}>Historique des formations</CardTitle>
                </Col>
                <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <RenderIf isTrue={data.length > 0}>
                    <CsvButton
                      color="primary"
                      onClick={() => {
                        downloadCsv(
                          convertToCSV(data, ";", getColumnsMapForCsv(columns)),
                          "export_formations_" + moment().format("YYYY-MM-DD"),
                        );
                      }}
                    />
                  </RenderIf>
                  <Button
                    color="primary"
                    style={{ marginRight: 10 }}
                    onClick={() => loadData()}
                  >
                    {isMillesime ? store.millesime.idmillesime : "Tous"}
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <RenderIf isTrue={loading}>
                <SpinLoadingAnimation />
              </RenderIf>
              <RenderIf isTrue={!loading}>
                <AgGrid
                  columnDefs={columns}
                  rowData={data}
                  showFilterLine
                  height={600}
                />
              </RenderIf>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ActionsGroupees>
  );
}
