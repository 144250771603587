export const columns = [
  {
    headerName: "Exploitation",
    field: "raisonsociale",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Nom",
    field: "nom",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Prénom",
    field: "prenom",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Adresse",
    field: "adresse",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Code postal",
    field: "codepostal",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Commune",
    field: "nomcommune",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Numéro Unilever",
    field: "numerounilever",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Code DC",
    field: "codedc",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "SIRET",
    field: "siret",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Mail",
    field: "mail",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Téléphone portable",
    field: "telephoneportable",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Organisme stockeur",
    field: "nomorganismestockeur",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Année d'adhésion (année récolte)",
    field: "millesimeadhesion",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Type de production",
    field: "libelletypeproduction",
    defaultFlex: 1,
    filter: true,
  },
];
