import {
  valueFormatterTonnage,
  valueFormatterSurface,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Exploitation",
    field: "raisonsociale",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Code DC",
    field: "codedc",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Code postal",
    field: "codepostal",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Commune",
    field: "nomcommune",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Date surface potentielle",
    field: "datesurfacepotentielle",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Surface potentielle printemps",
    field: "surfacepotentielleprintemps",
    defaultFlex: 1,
    filter: true,
    valueFormatter: valueFormatterTonnage,
  },
  {
    headerName: "Surface potentielle automne",
    field: "surfacepotentielleautomne",
    defaultFlex: 1,
    filter: true,
    valueFormatter: valueFormatterSurface,
  },
  {
    headerName: "Surface potentielle totale",
    field: "surfacepotentielletotale",
    defaultFlex: 1,
    filter: true,
    valueFormatter: valueFormatterSurface,
  },
  {
    headerName: "Tonnage totale",
    field: "tonnagetotal",
    defaultFlex: 1,
    filter: true,
    resizable: true,
    valueFormatter: valueFormatterSurface,
  },
];
