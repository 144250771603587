export const columns = [
  {
    headerfield: "Exploitation",
    field: "raisonsociale",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerfield: "Adresse",
    field: "adresse",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerfield: "Code postal",
    field: "codepostal",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerfield: "Commune",
    field: "nomcommune",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerfield: "Mail",
    field: "mail",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerfield: "Téléphone portable",
    field: "telephoneportable",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerfield: "Téléphone fixe",
    field: "telephonefixe",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerfield: "Code DC",
    field: "codedc",
    defaultFlex: 1,
    filter: true,
  },
];
