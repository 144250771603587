import colors from "../../../config/colors";
import {
  cellRendererSwitch,
  cellStyleSwitch,
  valueFormatterDate,
  valueFormatterUnite,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Exploitation",
    field: "raisonsociale",
    defaultFlex: 2,
    filter: true,
  },
  {
    headerName: "Numéro de contrat",
    field: "numerocontrat",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Organisme stockeur",
    field: "nomorganismestockeur",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Montant de la cotisation HT",
    field: "prixcotisation",
    valueFormatter: (params) => valueFormatterUnite(params, "€ HT"),
    defaultFlex: 2,
    filter: true,
  },
  {
    headerName: "Montant de la cotisation TTC",
    field: "prixcotisationttc",
    valueGetter: (params) =>
      parseFloat((params.data.prixcotisation * 1.2).toFixed(2)),
    valueFormatter: (params) => valueFormatterUnite(params, "€ TTC"),
    defaultFlex: 2,
    filter: true,
  },
  {
    headerName: "Date d'envoi de la facture",
    field: "dateenvoifacturecotisation",
    defaultFlex: 1,
    filter: true,
    valueFormatter: valueFormatterDate,
  },
  {
    headerName: "Abandon de contrat",
    field: "abandon",
    defaultFlex: 2,
    filter: true,
    cellStyle: (params) =>
      cellStyleSwitch(params.value, [
        {
          value: true,
          cellSwitch: {
            border: "1px solid",
            color: colors.danger.color,
            backgroundColor: colors.danger.background,
          },
        },
        {
          value: false,
          cellSwitch: {
            border: "1px solid",
            color: colors.success.color,
            backgroundColor: colors.success.background,
          },
        },
        {
          value: null,
          cellSwitch: null,
        },
      ]),
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, [
        {
          value: true,
          renderer: "OUI",
        },
        {
          value: false,
          renderer: "NON",
        },
        {
          value: null,
          renderer: null,
        },
      ]),
  },
  {
    headerName: "Pénalité payée",
    field: "penalitepayee",
    cellStyle: function (params) {
      switch (params.value) {
        case true:
          return {
            border: "1px solid",
            color: colors.success.color,
            backgroundColor: colors.success.background,
          };
        case false:
          return {
            border: "1px solid",
            color: colors.danger.color,
            backgroundColor: colors.danger.background,
          };
        default:
          return {
            border: "1px solid",
            color: colors.warning.color,
            backgroundColor: colors.warning.background,
          };
      }
    },
    cellRenderer: (params) => {
      switch (params.value) {
        case true:
          return "Payée";
        case false:
          return "Non payée";
        default:
          return "Pas de pénalité";
      }
    },
    defaultFlex: 2,
    filter: true,
  },
  {
    headerName: "Commentaire",
    field: "commentaire",
    defaultFlex: 2,
    filter: true,
  },
];
