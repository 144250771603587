import AuthService from "../../../config/AuthService";
import colors from "../../../config/colors";
import droits from "../../../config/CodesDroits";
import { cellRendererSwitch, cellStyleSwitch } from "../../../config/gridUtils";

export const columnsLivraison = [
  {
    headerName: "Silo",
    field: "nomsilo",
    suppressSizeToFit: true,
    filter: true,
  },
  {
    headerName: "Code postal",
    field: "codepostal",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Commune",
    field: "nomcommune",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Organisme stockeur",
    field: "nomorganismestockeur",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Actif",
    field: "actif",
    defaultFlex: 1,
    filter: true,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit(droits.typesdroits.SILOS_LIVRAISON, 30),
    cellEditor: "customSelectCellEditor",
    cellEditorParams: {
      values: [
        {
          value: true,
          label: "OUI",
        },
        {
          value: false,
          label: "NON",
        },
      ],
    },
    cellStyle: function (params) {
      return cellStyleSwitch(params.value, [
        {
          value: true,
          cellStyle: {
            border: "1px solid",
            color: colors.success.color,
            backgroundColor: colors.success.background,
          },
        },
        {
          value: false,
          cellStyle: {
            border: "1px solid",
            color: colors.danger.color,
            backgroundColor: colors.danger.background,
          },
        },
        {
          value: null,
          cellStyle: {
            border: "1px solid",
            color: colors.danger.color,
            backgroundColor: colors.danger.background,
          },
        },
      ]);
    },
    cellRenderer: (params) => {
      return cellRendererSwitch(params.value, [
        {
          value: true,
          renderer: "OUI",
        },
        {
          value: false,
          renderer: "NON",
        },
        {
          value: null,
          renderer: "NON",
        },
      ]);
    },
  },
];

export const columnsDefinitif = [
  {
    headerName: "Silo",
    field: "nomsilo",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Code postal",
    field: "codepostal",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Commune",
    field: "nomcommune",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Organisme stockeur",
    field: "nomorganismestockeur",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Actif",
    field: "actif",
    defaultFlex: 1,
    filter: true,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit(droits.typesdroits.SILOS_DEFINITIFS, 30),
    cellEditor: "customSelectCellEditor",
    cellEditorParams: {
      values: [
        {
          value: true,
          label: "OUI",
        },
        {
          value: false,
          label: "NON",
        },
      ],
    },
    cellStyle: function (params) {
      return cellStyleSwitch(params.value, [
        {
          value: true,
          cellStyle: {
            border: "1px solid",
            color: colors.success.color,
            backgroundColor: colors.success.background,
          },
        },
        {
          value: false,
          cellStyle: {
            border: "1px solid",
            color: colors.danger.color,
            backgroundColor: colors.danger.background,
          },
        },
        {
          value: null,
          cellStyle: {
            border: "1px solid",
            color: colors.danger.color,
            backgroundColor: colors.danger.background,
          },
        },
      ]);
    },
    cellRenderer: (params) => {
      return cellRendererSwitch(params.value, [
        {
          value: true,
          renderer: "OUI",
        },
        {
          value: false,
          renderer: "NON",
        },
        {
          value: null,
          renderer: "NON",
        },
      ]);
    },
  },
];
