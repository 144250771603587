export const columns = [
  {
    headerName: "Numéro de contrat",
    field: "numerocontrat",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Date du contrat",
    field: "datecontrat",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Exploitation",
    field: "raisonsociale",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Adresse",
    field: "adresse",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Code postal",
    field: "codepostal",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Commune",
    field: "nomcommune",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Mail",
    field: "mail",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Téléphone portable",
    field: "telephoneportable",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Téléphone fixe",
    field: "telephonefixe",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Organisme stockeur",
    field: "nomorganismestockeur",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Code DC",
    field: "codedc",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Variétés",
    children: [],
  },
];
