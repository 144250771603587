import {
  _missing,
  _warn,
  AgSelectSelector,
  KeyCode,
  PopupComponent,
  RefPlaceholder,
} from "ag-grid-community";
//import {EditCoreModule} from "ag-grid-community/dist/ag-grid-community.min";

var CustomSelectCellEditor = class extends PopupComponent {
  constructor() {
    super(
      /* html */
      `<div class="ag-cell-edit-wrapper">
                <ag-select class="ag-cell-editor" data-ref="eSelect"></ag-select>
            </div>`,
      [AgSelectSelector],
    );
    this.eSelect = RefPlaceholder;
    this.startedByEnter = false;
  }

  wireBeans(beans) {
    this.valueSvc = beans.valueSvc;
  }

  init(params) {
    this.focusAfterAttached = params.cellStartedEdit;
    const { eSelect, valueSvc, gos } = this;
    const { values, value, eventKey } = params;
    if (_missing(values)) {
      _warn(58);
      return;
    }
    this.startedByEnter = eventKey != null ? eventKey === KeyCode.ENTER : false;
    let hasValue = false;
    values.forEach((currentValue) => {
      const option = { value: currentValue.value };
      const valueFormatted = valueSvc.formatValue(
        params.column,
        null,
        currentValue.value,
      );
      option.text = currentValue.label;
      eSelect.addOption(option);
      hasValue = hasValue || value === currentValue.value;
    });
    if (hasValue) {
      eSelect.setValue(params.value, true);
    } else if (params.values.length) {
      eSelect.setValue(params.values[0].value, true);
    }
    const { valueListGap, valueListMaxWidth, valueListMaxHeight } = params;
    if (valueListGap != null) {
      eSelect.setPickerGap(valueListGap);
    }
    if (valueListMaxHeight != null) {
      eSelect.setPickerMaxHeight(valueListMaxHeight);
    }
    if (valueListMaxWidth != null) {
      eSelect.setPickerMaxWidth(valueListMaxWidth);
    }
    if (gos.get("editType") !== "fullRow") {
      this.addManagedListeners(this.eSelect, {
        selectedItem: () => params.stopEditing(),
      });
    }
  }

  afterGuiAttached() {
    if (this.focusAfterAttached) {
      this.eSelect.getFocusableElement().focus();
    }
    if (this.startedByEnter) {
      setTimeout(() => {
        if (this.isAlive()) {
          this.eSelect.showPicker();
        }
      });
    }
  }

  focusIn() {
    this.eSelect.getFocusableElement().focus();
  }

  getValue() {
    return this.eSelect.getValue();
  }

  isPopup() {
    return false;
  }
};

var CustomSelectEditorModule = {
  moduleName: "CustomSelectEditor",
  version: "33.0.2",
  userComponents: { customSelectCellEditor: CustomSelectCellEditor },
  dependsOn: [],
};

export default CustomSelectEditorModule;

/*

EXEMPLE DE DEFINITION DE COLONNE AVEC L'EDITEUR SELECT CUSTOM :

{
    headerName: "Abandon de contrat",
    field: "abandon",
    defaultFlex: 2,
    filter: true,
    editable: true,
    cellEditor: "customSelectCellEditor",
    cellEditorParams: {
      values: [
        {
          value: true,
          label: "OUI",
        },
        {
          value: false,
          label: "NON",
        },
      ],
    },
    cellStyle: (params) =>
      cellStyleSwitch(params.value, [
        {
          value: true,
          cellSwitch: {
            border: "1px solid",
            color: colors.danger.color,
            backgroundColor: colors.danger.background,
          },
        },
        {
          value: false,
          cellSwitch: {
            border: "1px solid",
            color: colors.success.color,
            backgroundColor: colors.success.background,
          },
        },
        {
          value: null,
          cellSwitch: null,
        },
      ]),
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, [
        {
          value: true,
          renderer: "OUI",
        },
        {
          value: false,
          renderer: "NON",
        },
        {
          value: null,
          renderer: null,
        },
      ]),
  },

*/
