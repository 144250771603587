export const columns = [
  {
    headerName: "Raison sociale",
    field: "raisonsociale",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Numéro SIRET",
    field: "siret",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Code postal",
    field: "codepostal",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Ville",
    field: "ville",
    defaultFlex: 1,
    filter: true,
  },
];
