import colors from "../../../config/colors";
import AuthService from "../../../config/AuthService";
import droits from "../../../config/CodesDroits";
import moment from "moment";

export const columns = [
  {
    headerName: "Numéro SIRET",
    field: "siret",
    headerTooltip: "SIRET",
    tooltipField: "siret",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Code OS",
    field: "codeos",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Code DC",
    field: "codedc",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Exploitation",
    field: "raisonsociale",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Nom",
    field: "nom",
    valueGetter: (params) => {
      return `${params.data.nom} ${params.data.prenom}`;
    },
    defaultFlex: 2,
    filter: true,
  },
  {
    headerName: "Adresse",
    field: "adresse",
    defaultFlex: 3,
    filter: true,
  },
  {
    headerName: "Commune",
    field: "nomcommune",
    valueGetter: (params) =>
      `${params.data.codepostal} ${params.data.nomcommune}`,
    defaultFlex: 3,
    filter: true,
  },
  {
    headerName: "Traçabilité reçue",
    field: "tracabiliterecue",
    cellStyle: function (params) {
      if (params.value == true) {
        return {
          border: "1px solid",
          color: colors.success.color,
          backgroundColor: colors.success.background,
        };
      } else if (params.value == false) {
        return {
          border: "1px solid",
          color: colors.danger.color,
          backgroundColor: colors.danger.background,
        };
      } else {
        return null;
      }
    },
    cellRenderer: (props) => {
      return (
        <>
          {props.value == true
            ? "OUI"
            : props.value == false
            ? "NON"
            : "Non renseigné"}
        </>
      );
    },
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Date de réception de la traçabilité",
    field: "datereceptiontracabilite",
    cellDataType: "date",
    valueFormatter: (params) =>
      params.value ? moment(params.value).format("DD/MM/YYYY") : null,
    defaultFlex: 1,
    filter: true,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit(
        droits.typesdroits.TRACABILITE_CONTRATS,
        droits.modalite.modification,
      ),
  },
  {
    headerName: "Date de vérification de la traçabilité",
    field: "dateverificationtracabilite",
    cellDataType: "date",
    valueFormatter: (params) =>
      params.value ? moment(params.value).format("DD/MM/YYYY") : null,
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Cotisation payée",
    field: "cotisationpayee",
    cellStyle: function (params) {
      if (params.value == true) {
        return {
          border: "1px solid",
          color: colors.success.color,
          backgroundColor: colors.success.background,
        };
      } else if (params.value == false) {
        return {
          border: "1px solid",
          color: colors.danger.color,
          backgroundColor: colors.danger.background,
        };
      } else {
        return null;
      }
    },
    cellRenderer: (props) => {
      return (
        <>
          {props.value == true
            ? "OUI"
            : props.value == false
            ? "NON"
            : "Non renseigné"}
        </>
      );
    },
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Pénalité payée",
    field: "penalitepayee",
    cellStyle: function (params) {
      if (params.value == true) {
        return {
          border: "1px solid",
          color: colors.success.color,
          backgroundColor: colors.success.background,
        };
      } else if (params.value == false) {
        return {
          border: "1px solid",
          color: colors.danger.color,
          backgroundColor: colors.danger.background,
        };
      } else {
        return {
          border: "1px solid",
          color: colors.warning.color,
          backgroundColor: colors.warning.background,
        };
      }
    },
    cellRenderer: (props) => {
      return (
        <>
          {props.value == true
            ? "Payée"
            : props.value == false
            ? "Non payée"
            : "Pas de pénalité"}
        </>
      );
    },
    defaultFlex: 1,
    filter: true,
  },
];
