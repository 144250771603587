export const columns = [
  {
    headerName: "Exploitation",
    field: "raisonsociale",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Organisme stockeur",
    field: "nomorganismestockeur",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Adresse e-mail",
    field: "mail",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Téléphone fixe",
    field: "telephonefixe",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Téléphone portable",
    field: "telephoneportable",
    defaultFlex: 1,
    filter: true,
  },
];
