export const columns = [
  {
    headerName: "Exploitation",
    field: "raisonsociale",
    defaultFlex: 1,
    filter: true,
  },
];

export const gridStyle = { minHeight: 500, color: "#ffffff" };

export const defaultFilterValue = [
  { name: "raisonsociale", type: "string", operator: "contains", value: "" },
  { name: "mail", type: "string", operator: "contains", value: "" },
  {
    name: "telephonefixe",
    type: "string",
    operator: "contains",
    value: "",
  },
  {
    name: "telephoneportable",
    type: "string",
    operator: "contains",
    value: "",
  },
];
