export const columns = [
  {
    headerName: "Millesime",
    field: "millesime",
    flex: 1,
    filter: true,
  },
  {
    headerName: "Tonnage demandé",
    field: "tonnage",
    flex: 1,
    filter: true,
  },
  {
    headerName: "Rendement estimé",
    field: "estimationrendement",
    flex: 1,
    filter: true,
  },
  {
    headerName: "Surface nécessaire",
    field: "surface",
    flex: 1,
    filter: true,
  },
  {
    headerName: "Surface contractualisée",
    field: "surfacecontractualisee",
    flex: 1,
    filter: true,
  },
];
