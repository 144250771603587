import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import { columns } from "../../components/Grids/ListeUtilisateur/tableutilisateur";
import AgGrid from "../../components/AgGrid";

export default function TableUtilisateurs(props) {
  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Liste des utilisateurs</CardTitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {props.loading && <SpinLoadingAnimation />}
        {!props.loading && (
          <AgGrid
            columnDefs={columns}
            rowData={props.utilisateurs}
            onRowClicked={props.onClickUtilisateur}
            showFilterLine
            height={600}
          />
        )}
      </CardBody>
    </Card>
  );
}
