import { useState } from "react";
import { Button, Col, Row } from "reactstrap";
import ValidateButton from "./Buttons/ValidateButton";
import AgGrid from "./AgGrid";

export default function AffectationGrid(props) {
  const [dataSourceGauche, setDataSourceGauche] = useState(
    props.dataSourceGauche,
  );
  const [dataSourceDroite, setDataSourceDroite] = useState(
    props.dataSourceDroite,
  );
  const [allData] = useState(
    props.dataSourceDroite.concat(props.dataSourceGauche),
  );

  const moveLeftToRight = (value) => {
    const itemToMove = dataSourceGauche.find(
      (data) => data[props.idProperty] === value,
    );
    if (!itemToMove) return;

    setDataSourceDroite((prevDataSourceDroite) => [
      itemToMove,
      ...prevDataSourceDroite,
    ]);

    setDataSourceGauche((prevDataSourceGauche) => {
      return prevDataSourceGauche.filter((data) => {
        return data[props.idProperty] !== value;
      });
    });
  };

  const moveRightToLeft = (value) => {
    const itemToMove = dataSourceDroite.find(
      (data) => data[props.idProperty] === value,
    );
    if (!itemToMove) return;

    setDataSourceGauche((prevDataSourceGauche) => [
      itemToMove,
      ...prevDataSourceGauche,
    ]);

    setDataSourceDroite((prevDataSourceDroite) => {
      return prevDataSourceDroite.filter((data) => {
        return data[props.idProperty] !== value;
      });
    });
  };

  return (
    <>
      <Row style={{ marginBottom: 10 }}>
        <Col md="6" style={{ marginBottom: 10 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <h5>{props.titreGauche}</h5>
            <Button
              color="primary"
              style={{ padding: 5, fontSize: 10, cursor: "pointer" }}
              onClick={() => {
                setDataSourceDroite(allData);
                setDataSourceGauche([]);
              }}
            >
              Tout ajouter <i className="fa-solid fa-arrow-right"></i>
            </Button>
          </div>
          <div
            style={{
              overflow: "auto",
              fontSize: 12,
              maxHeight: 500,
              marginBottom: 10,
            }}
          >
            <AgGrid
              columnDefs={props.columns}
              rowData={dataSourceGauche}
              onRowClicked={(params) =>
                moveLeftToRight(params.data[props.idProperty])
              }
              showFilterLine
            />
          </div>
          {dataSourceGauche.length} éléments
        </Col>
        <Col md="6" style={{ marginBottom: 10 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <Button
              color="primary"
              style={{ padding: 5, fontSize: 10, cursor: "pointer" }}
              onClick={() => {
                setDataSourceGauche(allData);
                setDataSourceDroite([]);
              }}
            >
              <i className="fa-solid fa-arrow-left"></i> Tout retirer
            </Button>
            <h5>{props.titreDroite}</h5>
          </div>
          <div
            style={{
              overflow: "auto",
              fontSize: 12,
              maxHeight: 500,
              marginBottom: 10,
            }}
          >
            <AgGrid
              columnDefs={props.columns}
              rowData={dataSourceDroite}
              onRowClicked={(params) =>
                moveRightToLeft(params.data[props.idProperty])
              }
              showFilterLine
            />
          </div>
          {dataSourceDroite.length} éléments
        </Col>
      </Row>
      <Row>
        <Col>
          <ValidateButton
            onClick={() =>
              props.onClickValider(dataSourceGauche, dataSourceDroite)
            }
          />
        </Col>
      </Row>
    </>
  );
}
