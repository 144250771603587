export const columns = [
  {
    headerName: "Raison sociale",
    field: "raisonsociale",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "SIRET",
    field: "siret",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Code DC",
    field: "codedc",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Adresse",
    field: "adresse",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Code postal",
    field: "codepostal",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Commune",
    field: "nomcommune",
    defaultFlex: 1,
    filter: true,
  },
];

export const gridStyle = { minHeight: 400, color: "#ffffff" };

export const defaultFilterValue = [
  { field: "raisonsociale", type: "string", operator: "contains", value: "" },
  { field: "adresse", type: "string", operator: "contains", value: "" },
  { field: "codepostal", type: "string", operator: "contains", value: "" },
  { field: "nomcommune", type: "string", operator: "contains", value: "" },
  { field: "siret", type: "float", operator: "contains", value: "" },
  {
    field: "codedc",
    type: "float",
    operator: "contains",
    value: "",
  },
];
