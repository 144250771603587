import { cellRendererSwitch, cellStyleSwitch } from "../../../config/gridUtils";
import colors from "../../../config/colors";

export const columns = [
  {
    headerName: "Exploitation",
    field: "raisonsociale",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Numero de contrat",
    field: "numerocontrat",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Nombre de parcelles totales",
    field: "nbparcellestotales",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Nombre de parcelles restantes",
    field: "nbparcellesrestantes",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Contrat abandonné",
    field: "abandon",
    cellStyle: function (params) {
      return cellStyleSwitch(params.value, [
        {
          value: true,
          cellStyle: {
            border: "1px solid",
            color: colors.danger.color,
            backgroundColor: colors.danger.background,
          },
        },
        {
          value: false,
          cellStyle: {
            border: "1px solid",
            color: colors.success.color,
            backgroundColor: colors.success.background,
          },
        },
        { value: null, cellStyle: null },
      ]);
    },
    cellRenderer: (params) => {
      return cellRendererSwitch(params.value, [
        {
          value: true,
          renderer: "OUI",
        },
        {
          value: false,
          renderer: "NON",
        },
        {
          value: null,
          renderer: null,
        },
      ]);
    },
    defaultFlex: 1,
    filter: true,
  },
];
