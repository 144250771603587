export const columns = [
  {
    headerName: "Exploitation",
    field: "raisonsociale",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Année d'adhésion",
    field: "millesimeadhesion",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Code DC",
    field: "codedc",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Adresse",
    field: "adresse",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Code postal",
    field: "codepostal",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Commune",
    field: "nomcommune",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Mail",
    field: "mail",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Téléphone portable",
    field: "telephoneportable",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Téléphone fixe",
    field: "telephonefixe",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Zone de production",
    field: "libellezoneproduction",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Type de production",
    field: "libelletypeproduction",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Variete",
    field: "libellevariete",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Silo",
    field: "nomsilo",
    defaultFlex: 1,
    filter: true,
  },
];
