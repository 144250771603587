import moment from "moment/moment";

export const valueFormatterUnite = (params, unite) =>
  params.value ? `${params.value} ${unite}` : "";
export const valueFormatterSurface = (params) =>
  valueFormatterUnite(params, "ha");
export const valueFormatterTonnage = (params) =>
  valueFormatterUnite(params, "t");

export const valueFormatterDate = (params) =>
  params.value ? moment(params.value).format("DD/MM/YYYY") : null;

export const cellStyleSwitch = (value, options) => {
  return options.find((o) => o.value === value).cellStyle;
};

export const cellRendererSwitch = (value, options) => {
  return options.find((o) => o.value === value).renderer;
};

export const getColumnsMapForCsv = (columns) => {
  const result = {};

  const extractColumns = (cols) => {
    cols.forEach((col) => {
      result[col.field] = col.headerName;

      // ici on vérifie si la colonne a des enfants
      if (col.children && Array.isArray(col.children)) {
        // si oui, on itère à travers en utilisant la recursion
        extractColumns(col.children);
      }
    });
  };

  extractColumns(columns);
  return result;
};
