import colors from "../../../config/colors";
import {
  cellRendererSwitch,
  cellStyleSwitch,
  valueFormatterSurface,
  valueFormatterTonnage,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Validé",
    field: "libellevalidationadministrative",
    cellStyle: function (params) {
      return cellStyleSwitch(params.data.idetatvalidationadministrative, [
        {
          value: 1,
          cellStyle: {
            border: "1px solid",
            color: colors.danger.color,
            backgroundColor: colors.danger.background,
          },
        },
        {
          value: 2,
          cellStyle: {
            border: "1px solid",
            color: colors.warning.color,
            backgroundColor: colors.warning.background,
          },
        },
        {
          value: 3,
          cellStyle: {
            border: "1px solid",
            color: colors.warning.color,
            backgroundColor: colors.warning.background,
          },
        },
        {
          value: 4,
          cellStyle: {
            border: "1px solid",
            color: colors.success.color,
            backgroundColor: colors.success.background,
          },
        },
        { value: null, cellStyle: null },
      ]);
    },
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Date de saisie",
    field: "datesurfacepotentielle",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Exploitation",
    field: "exploitation",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Code DC",
    field: "codedc",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Année d'adhésion",
    field: "millesimeadhesion",
    hide: true,
    defaultVisible: false,
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Commune",
    field: "commune",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Surface printemps (ha)",
    field: "surfacepotentielleprintemps",
    valueFormatter: valueFormatterSurface,
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Surface automne (ha)",
    field: "surfacepotentielleautomne",
    valueFormatter: valueFormatterSurface,
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Surface totale (ha)",
    field: "surfacepotentielletotale",
    valueFormatter: valueFormatterSurface,
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Tonnage estimé (t)",
    field: "tonnageestime",
    valueFormatter: valueFormatterTonnage,
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Type de production",
    field: "libelletypeproduction",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Quantité de semence",
    field: "quantitesemence",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Variété",
    field: "libellevariete",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Rendement de référence moyen",
    field: "rendementmoyen",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Organisme stockeur",
    field: "nomorganismestockeur",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Abandonné",
    field: "abandon",
    cellStyle: function (params) {
      return cellStyleSwitch(params.value, [
        {
          value: true,
          cellStyle: {
            border: "1px solid",
            color: colors.danger.color,
            backgroundColor: colors.danger.background,
          },
        },
        {
          value: false,
          cellStyle: {
            border: "1px solid",
            color: colors.success.color,
            backgroundColor: colors.success.background,
          },
        },
        { value: null, cellStyle: null },
      ]);
    },
    cellRenderer: (params) => {
      return cellRendererSwitch(params.value, [
        {
          value: true,
          renderer: "OUI",
        },
        {
          value: false,
          renderer: "NON",
        },
        {
          value: null,
          renderer: null,
        },
      ]);
    },
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Commentaires",
    field: "commentaire",
    headerTooltip: "Commentaire",
    tooltipField: "commentaire",
    defaultFlex: 1,
    filter: true,
  },
];
