import { cellRendererSwitch } from "../../../config/gridUtils";
import colors from "../../../config/colors";

export const columns = [
  {
    headerName: "Exploitation",
    field: "raisonsociale",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Adresse",
    field: "adresse",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Code postal",
    field: "codepostal",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Commune",
    field: "nomcommune",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Contrat renseigné",
    field: "hascontrat",
    defaultFlex: 1,
    filter: true,
    cellStyle: function (params) {
      if (params.value === true) {
        return {
          border: "1px solid",
          color: colors.danger.color,
          backgroundColor: colors.danger.background,
        };
      } else if (params.value === false) {
        return {
          border: "1px solid",
          color: colors.success.color,
          backgroundColor: colors.success.background,
        };
      } else {
        return null;
      }
    },
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, [
        {
          value: true,
          renderer: "OUI",
        },
        {
          value: false,
          renderer: "NON",
        },
        {
          value: null,
          renderer: null,
        },
      ]),
  },
];
