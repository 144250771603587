import colors from "../../../config/colors";
import {
  cellRendererSwitch,
  cellStyleSwitch,
  valueFormatterDate,
} from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Exploitation",
    field: "raisonsociale",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Numéro de contrat",
    field: "numerocontrat",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Date du contrat",
    field: "datecontrat",
    cellDataType: "date",
    valueFormatter: valueFormatterDate,
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Date d'envoi",
    field: "dateenvoi",
    cellDataType: "date",
    valueFormatter: valueFormatterDate,
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Date de retour",
    field: "dateretour",
    cellDataType: "date",
    valueFormatter: valueFormatterDate,
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Convention signée",
    field: "signee",
    cellStyle: (params) =>
      cellStyleSwitch(params.value, [
        {
          value: true,
          cellSwitch: {
            border: "1px solid",
            color: colors.success.color,
            backgroundColor: colors.success.background,
          },
        },
        {
          value: false,
          cellSwitch: {
            border: "1px solid",
            color: colors.danger.color,
            backgroundColor: colors.danger.background,
          },
        },
        {
          value: null,
          cellSwitch: null,
        },
      ]),
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, [
        {
          value: true,
          renderer: "OUI",
        },
        {
          value: false,
          renderer: "NON",
        },
        {
          value: null,
          renderer: null,
        },
      ]),
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Mail",
    field: "mail",
    defaultFlex: 1,
    filter: true,
  },
];
