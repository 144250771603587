import AuthService from "../../../config/AuthService";
import droits from "../../../config/CodesDroits";

export const columns = [
  {
    headerName: "Statut juridique",
    field: "libellestatut",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Exploitation",
    field: "raisonsociale",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "SIRET",
    field: "siret",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Adresse",
    field: "adresse",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Code postal",
    field: "codepostal",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Commune",
    field: "nomcommune",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Organisme stockeur",
    field: "nomorganismestockeur",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Code OS",
    field: "codeos",
    cellDataType: "string",
    defaultFlex: 1,
    editable: AuthService.hasDroit(
      droits.typesdroits.CODES_OS,
      droits.modalite.modification,
    ),
    filter: true,
  },
  {
    headerName: "Code DC",
    field: "codedc",
    cellDataType: "string",
    defaultFlex: 1,
    editable: AuthService.hasDroit(
      droits.typesdroits.CODES_OS,
      droits.modalite.modification,
    ),
    filter: true,
  },
];
