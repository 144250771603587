import { useCallback, useContext } from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { columns } from "../../components/Grids/CommandeIndustriel/commandes";
import { RenderIf } from "../../config/utils";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import droits from "../../config/CodesDroits";
import StoreContext from "../../context/StoreContext";
import AgGrid from "../../components/AgGrid";

export default function TableCommandeIndustrielle(props) {
  const store = useContext(StoreContext);

  const onRowClicked = useCallback(async (event) => {
    props.onSelectCommande(event.data);
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>
          Récapitulatif des commandes industrielles
        </CardTitle>
      </CardHeader>
      <CardBody>
        <RenderIf isTrue={props.loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf
          isTrue={
            !props.loading &&
            props.commandes !== null &&
            props.commandes !== [] &&
            props.commandes !== undefined
          }
        >
          <AgGrid
            rowData={props.commandes}
            columnDefs={columns}
            onRowClicked={
              store.utilisateur.hasDroits(
                droits.typesdroits.GESTION_COMMANDE,
                droits.modalite.modification,
              )
                ? onRowClicked
                : null
            }
            height={350}
          />
        </RenderIf>
      </CardBody>
    </Card>
  );
}
