import { useState } from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import useConstructor from "../../config/hooks/useConstructor";
import CloseButton from "../../components/Buttons/CloseButton";
import { columns } from "../../components/Grids/ListeUtilisateur/ficheajoutdossier";
import AffectationGrid from "../../components/AffectationGrid";
import ExploitationController from "../../config/apiUtils/ExploitationController";
import UtilisateurController from "../../config/apiUtils/UtilisateurController";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import { createNotification, RenderIf } from "../../config/utils";

export default function FicheAjoutDossier(props) {
  const [acces, setAcces] = useState([]);
  const [exploitations, setExploitations] = useState([]);
  const [loading, setLoading] = useState(false);

  useConstructor(async () => {
    setLoading(true);
    const resAcces = await UtilisateurController.getAccesUtilisateur(
      props.utilisateur.idutilisateur,
    );
    var resExploitations = await ExploitationController.getAllExploitations(
      "",
      "",
      props?.utilisateur?.idorganismestockeurs?.length > 0
        ? props.utilisateur?.idorganismestockeurs[0]
        : null,
    );
    resExploitations = resExploitations.filter((exp) => {
      return !resAcces.find((ac) => {
        return ac.idexploitation === exp.idexploitation;
      });
    });
    setExploitations(resExploitations);
    setAcces(resAcces);
    setLoading(false);
  });

  const onClickValider = async (listeGauche, listeDroite) => {
    const acces = listeDroite.map((x) => x.idexploitation);
    const res = await UtilisateurController.createAccesUtilisateur(
      props.utilisateur.idutilisateur,
      acces,
    );
    if (res.status === 200) {
      createNotification("success", "Succès", "Accès modifiés avec succès !");
      props.onClickClose();
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={"h5"}>
          Affectation des dossiers - {props.utilisateur.prenom}{" "}
          {props.utilisateur.nom}
        </CardTitle>
        <CloseButton onClick={props.onClickClose} />
      </CardHeader>
      <CardBody>
        <RenderIf isTrue={loading}>
          <SpinLoadingAnimation />
        </RenderIf>
        <RenderIf isTrue={!loading}>
          <AffectationGrid
            titreGauche="Dossiers"
            titreDroite="Dossiers affectés"
            dataSourceGauche={exploitations}
            dataSourceDroite={acces}
            columns={columns}
            onClickValider={onClickValider}
            idProperty="idexploitation"
          />
        </RenderIf>
      </CardBody>
    </Card>
  );
}
