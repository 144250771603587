import { themeQuartz, colorSchemeDarkBlue } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useMemo, useRef } from "react";
import { AG_GRID_LOCALE_FR } from "@ag-grid-community/locale";
import { RenderIf } from "../config/utils";
import SpinLoadingAnimation from "./SpinLoadingAnimation";

const customFrenchLocale = {
  ...AG_GRID_LOCALE_FR,
  true: "Oui",
  false: "Non",
};

export default function AgGrid({
  rowData,
  columnDefs,
  onCellEditingStopped,
  onRowClicked,
  showFilterLine = false,
  height = 500,
  pagination = false,
  paginationPageSize = 50,
  paginationPageSizeSelector = [25, 50, 100, 200],
  exportCsv = false,
  selectable = false,
  multiSelect = true,
  onRowSelectionChanged = null,
}) {
  const gridRef = useRef(null);
  const theme = useMemo(() => {
    return themeQuartz.withPart(colorSchemeDarkBlue).withParams({
      backgroundColor: "rgb(42,51,63)",
      oddRowBackgroundColor: "rgb(73,100,113)",
    });
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 180,
      filter: true,
      floatingFilter: true,
    };
  }, []);

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv({
      fileName: "test",
      exportedRows: "filteredAndSorted",
    });
  }, []);

  const loadingCellRenderer = useCallback(SpinLoadingAnimation, []);
  const loadingCellRendererParams = useMemo(() => {
    return {
      loadingMessage: "Chargement en cours...",
    };
  }, []);

  const rowSelection = useMemo(() => {
    return {
      mode: multiSelect ? "multiRow" : "singleRow",
      selectAll: "filtered",
      enableClickSelection: true,
    };
  }, []);

  const onRowSelected = useCallback((event) => {
    if (selectable && onRowSelectionChanged != null) {
      onRowSelectionChanged(event.api.getSelectedNodes());
    }
  }, []);

  return (
    <>
      <RenderIf isTrue={exportCsv}>
        <div>
          <button onClick={onBtnExport}>Download CSV export file</button>
        </div>
      </RenderIf>
      <div style={{ height }}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={showFilterLine ? defaultColDef : null}
          theme={theme}
          pagination={pagination}
          paginationPageSize={paginationPageSize}
          paginationPageSizeSelector={paginationPageSizeSelector}
          onRowClicked={onRowClicked}
          onCellEditingStopped={onCellEditingStopped}
          localeText={customFrenchLocale}
          tooltipShowDelay={500}
          stopEditingWhenCellsLoseFocus
          applyMiniFilterWhileTyping
          loadingCellRenderer={loadingCellRenderer}
          loadingCellRendererParams={loadingCellRendererParams}
          rowSelection={selectable ? rowSelection : null}
          onRowSelected={onRowSelected}
        />
      </div>
    </>
  );
}
