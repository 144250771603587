import { cellRendererSwitch, cellStyleSwitch } from "../../../config/gridUtils";
import colors from "../../../config/colors";

export const columns = [
  {
    headerName: "Nom",
    field: "nom",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Prénom",
    field: "prenom",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Adresse",
    field: "adresse",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Code postal",
    field: "codepostal",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Commune",
    field: "nomcommune",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Adresse e-mail",
    field: "mail",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Téléphone",
    field: "telephone",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Profil",
    field: "libelleprofil",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Organisme stockeur",
    field: "nomorganismestockeur",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Etat",
    field: "disabled",
    defaultFlex: 1,
    filter: true,
    resizable: true,
    cellStyle: (params) =>
      cellStyleSwitch(params.value, [
        {
          value: true,
          cellSwitch: {
            border: "1px solid",
            color: colors.danger.color,
            backgroundColor: colors.danger.background,
          },
        },
        {
          value: false,
          cellSwitch: {
            border: "1px solid",
            color: colors.success.color,
            backgroundColor: colors.success.background,
          },
        },
        {
          value: null,
          cellSwitch: null,
        },
      ]),
    cellRenderer: (params) =>
      cellRendererSwitch(params.value, [
        {
          value: true,
          renderer: "Désactivé",
        },
        {
          value: false,
          renderer: "Activé",
        },
        {
          value: null,
          renderer: null,
        },
      ]),
  },
];
