import {
  cellRendererSwitch,
  cellStyleSwitch,
  valueFormatterDate,
} from "../../../config/gridUtils";
import colors from "../../../config/colors";

export const columns = [
  {
    headerName: "Exploitation",
    field: "raisonsociale",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Code DC",
    field: "codedc",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Formation",
    field: "libelleformation",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Nom",
    field: "nom",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Prénom",
    field: "prenom",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Mail",
    field: "mail",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Téléphone portable",
    field: "telephoneportable",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Téléphone fixe",
    field: "telephonefixe",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Date de la formation",
    field: "dateformation",
    defaultFlex: 1,
    cellDataType: "date",
    valueFormatter: valueFormatterDate,
    filter: true,
  },
  {
    headerName: "Millésime",
    field: "millesime",
    defaultFlex: 1,
    filter: true,
  },
  {
    headerName: "Formation suivie",
    field: "suivi",
    defaultFlex: 1,
    filter: true,
    cellStyle: function (params) {
      return cellStyleSwitch(params.value, [
        {
          value: true,
          cellStyle: {
            border: "1px solid",
            color: colors.success.color,
            backgroundColor: colors.success.background,
          },
        },
        {
          value: false,
          cellStyle: {
            border: "1px solid",
            color: colors.danger.color,
            backgroundColor: colors.danger.background,
          },
        },
        { value: null, cellStyle: null },
      ]);
    },
    cellRenderer: (params) => {
      return cellRendererSwitch(params.value, [
        {
          value: true,
          renderer: "OUI",
        },
        {
          value: false,
          renderer: "NON",
        },
        {
          value: null,
          renderer: null,
        },
      ]);
    },
  },
  {
    headerName: "Commentaire",
    field: "commentaire",
    defaultFlex: 1,
    headerTooltip: "Commentaire",
    tooltipField: "commentaire",
    filter: true,
  },
];
