import { useContext, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import {
  columnsDefinitif,
  columnsLivraison,
} from "../../components/Grids/Silos/tablesilos";
import SiloController from "../../config/apiUtils/SiloController";
import { createNotification, RenderIf } from "../../config/utils";
import StoreContext from "../../context/StoreContext";
import AddButton from "../../components/Buttons/AddButton";
import droits from "../../config/CodesDroits";
import AgGrid from "../../components/AgGrid";

export default function TableSilos(props) {
  const store = useContext(StoreContext);
  const [silosLivraison, setSilosLivraison] = useState(
    store.silos.filter((silo) => silo.idreftypesilo === 1),
  );
  const [silosDefinitifs, setSilosDefinitifs] = useState(
    store.silos.filter((silo) => silo.idreftypesilo === 2),
  );

  useEffect(() => {
    setSilosLivraison(store.silos.filter((silo) => silo.idreftypesilo === 1));
    setSilosDefinitifs(store.silos.filter((silo) => silo.idreftypesilo === 2));
  }, [store.silos]);

  const onEditComplete = async (params) => {
    const res = await SiloController.activateSilo(
      params.data.idsilo,
      store.millesime.idmillesime,
      params.newValue,
    );

    if (res.status === 200) {
      createNotification("success", "Succès", res.data.message);
      const tmp = [...store.silos];
      tmp.find((silo) => silo.idsilo === params.data.idsilo).actif =
        params.newValue;
      store.setSilos(tmp);
    } else {
      createNotification(
        "error",
        "Erreur",
        "Erreur lors de la modification du silo...",
      );
    }
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag="h5">
                Liste des silos de {store.millesime.idmillesime}
              </CardTitle>
            </Col>
            <RenderIf
              isTrue={
                store.utilisateur.hasDroits(
                  droits.typesdroits.SILOS_LIVRAISON,
                  droits.modalite.creation,
                ) ||
                store.utilisateur.hasDroits(
                  droits.typesdroits.SILOS_DEFINITIFS,
                  droits.modalite.creation,
                )
              }
            >
              <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
                <AddButton onClick={props.onClickNewSilo} />
              </Col>
            </RenderIf>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={6} style={{ marginBottom: 15 }}>
              <h5>Silos de livraison</h5>
              <AgGrid
                columnDefs={columnsLivraison}
                rowData={silosLivraison}
                onCellEditingStopped={onEditComplete}
                showFilterLine
              />
            </Col>
            <Col md={6} style={{ marginBottom: 15 }}>
              <h5>Silos définitifs</h5>
              <AgGrid
                columnDefs={columnsDefinitif}
                rowData={silosDefinitifs}
                onCellEditingStopped={onEditComplete}
                showFilterLine
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
